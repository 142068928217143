app.components.news = {
    onReady: function () {
        if ($('.single').length) {
            return true;
        }
    },
    addListener: function () {

        var that = this;

        if ($('.sticky').length) {
            $('.sticky').scrollToFixed({
                marginTop: $('header').height() + 160,
                limit: $('#section--0').offset().top + $('#section--0').height() - $('.c-teaser__image').height() + $('header').height() + 160,
                removeOffsets: true,
                preAbsolute : function(){
                    $('.sticky').addClass('sticky--absolute');
                },
                preFixed : function(){
                    $('.sticky').removeClass('sticky--absolute');
                }
            });
        }

    }
};
