app.components.form = {
    onReady: function () {
        if ($('.wpforms-container').length) {
            return true;
        }
    },
    addListener: async function () {

        const container = $('.wpforms-container');

        Promise.all([
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.radio.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.checkbox.js')
        ]).then(() => {
            jcf.replaceAll(container);
        }).catch(error => {
            console.error('Error loading modules:', error);
        });

        $('input, textarea, select', container).on('input change', function () {
            var $field = $(this).closest('div.wpforms-field');
            if (this.value) {
                $field.addClass('field--not-empty');
            } else {
                $field.removeClass('field--not-empty');
            }
        }).trigger('input');

        $('input, textarea, select', container).on('focusin', function () {
            var $field = $(this).closest('div.wpforms-field');
            $field.addClass('field--not-empty');
        }).on('focusout', function () {
            $(this).trigger('input')
        });

        $('.wpforms-recaptcha-hidden').attr('area-hidden', 'true');

        // Formular für ärztliche Zuweisungen
        if (typeof wpforms_locations !== 'undefined' && wpforms_locations) {
            let locations = JSON.parse(wpforms_locations);
            let selectElement = document.getElementById('wpforms-4989-field_23');
            let options = {};
            Array.from(selectElement.options).forEach(option => {
                options[option.value] = option.text;
            });
            selectElement.innerHTML = '';
            let option = document.createElement('option');
            option.value = '';
            option.text = '';
            selectElement.appendChild(option);
            for (let location in locations) {
                let optgroup = document.createElement('optgroup');
                optgroup.label = location;
                locations[location].forEach(function(id) {
                    if (options[id]) {
                        let option = document.createElement('option');
                        option.value = id;
                        option.text = options[id];
                        optgroup.appendChild(option);
                    }
                });
                selectElement.appendChild(optgroup);
            }
        }
    }
};
