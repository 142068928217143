app.components.masonry = {
    onReady: function () {
        if ($('.section--masonry').length) {
            return true;
        }
    },
    addListener: function () {

        // super small and trash masonry layout with 2 columns
        $('.section--masonry').each(function () {

            let masonryContainer = $('.container', this);

            // remove responsive classes
            $('.col', masonryContainer).removeClass(function (index, className) {
                return (className.match(/(^|\s)col-\S+/g) || []).join(' ');
            });

            // save columns
            let entries = [];
            $('.col', masonryContainer).each(function (i, e) {
                entries.push(this);
                $(this).remove();
            });

            // create columns
            $(masonryContainer).append('<div class="grid-col d-none d-md-block col-md-6"></div><div class="grid-col d-none d-md-block col-md-6"></div>');
            $(masonryContainer).append('<div class="grid-col d-block d-md-none col-xs-12"></div>');

            let columns = [];
            columns[0] = $('.grid-col.col-md-6', masonryContainer).eq(0);
            columns[1] = $('.grid-col.col-md-6', masonryContainer).eq(1);
            columns[2] = $('.grid-col.col-xs-12', masonryContainer).eq(0);

            // move col to grid col
            $(entries).each(function (i, e) {
                columns[(i % 2 ? 1 : 0)].append($(e).clone());
                columns[2].append($(e).clone());
            });

        });

    }
};
