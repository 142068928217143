// Dynamically include all component JS files
function importAll(r) {
    r.keys().forEach(r);
}

import './assets/icons/icons.font';
import './assets/sass/main.scss';

importAll(require.context('./assets/js/drop-in/', true, /\.js$/));
importAll(require.context('./components/', true, /\.js$/));

$(function(){
    app.init();
});
