import ScrollReveal from 'scrollreveal'

$(function () {
    ScrollReveal().reveal('main > .section > .container > .col', {
        duration: 500,
        origin: 'bottom',
        distance: '10px',
        easing: 'ease-in-out',
        interval: 150,
        delay: 250,
        useDelay: 'onLoad'
    });
});
