app.components.tabs = {
    global: false,
    onReady: function () {
        if ($('.c-tabs').length) {
            return true;
        }
    },
    addListener: function () {

        var $container = $('.c-tabs');

        $(".c-tabs__tab", $container).hide();
        $("ul.c-tabs__nav li:eq(0) a", $container).addClass("active").show();
        $(".c-tabs__tab:first", $container).show();

        $("ul.c-tabs__nav li a", $container).on('click', function () {
            let $this = $(this);
            let activeTab = $this.data("href");
            $("ul.c-tabs__nav li a", $container).removeClass("active");
            $(this).closest("li").find("a").addClass("active");
            $(".c-tabs__tab", $container).hide();
            $(activeTab, $container).fadeIn();
            return false;
        });


        $(".c-tabs__nav", $container).on('scroll', function (e) {
           if (e.target.scrollLeft <= 0) {
                $(".c-tabs__nav-controls__prev", $container).addClass("disabled");
           } else {
                $(".c-tabs__nav-controls__prev", $container).removeClass("disabled");
           }
           if (e.target.scrollLeft >= e.target.scrollWidth - e.target.offsetWidth) {
                $(".c-tabs__nav-controls__next", $container).addClass("disabled");
           } else {
                $(".c-tabs__nav-controls__next", $container).removeClass("disabled");
           }
        });

        $(".c-tabs__nav-controls__prev", $container).on('click', function () {
            const nav = this.closest('.c-tabs').querySelector(".c-tabs__nav");
            nav.scrollBy({
                top: 0,
                left: nav.offsetWidth / -2,
                behavior: "smooth",
            });
        });

        $(".c-tabs__nav-controls__next", $container).on('click', function () {
            const nav = this.closest('.c-tabs').querySelector(".c-tabs__nav");
            nav.scrollBy({
                top: 0,
                left: nav.offsetWidth / 2,
                behavior: "smooth",
            })
        });

    },
    resize: function () {
        const gradientWidth = $(".c-tabs__nav-gradient").width();
        const lastMenuItem = $(".c-tabs__nav li:last-child");
        if (window.innerWidth >= 1024) {
            lastMenuItem.css("margin-right", gradientWidth * 0.8);
        } else {
            lastMenuItem.css("margin-right", 0);
        }
    },
};
