app.components.iframe = {
    onReady: function () {
        if ($('.c-iframe__iframe').length) {
            return true;
        }
    },
    resize: function () {
        var iframe = {};
        iframe.el = $('.c-iframe__iframe');
        iframe.width = iframe.el.width();
        iframe.height = iframe.el.width() / iframe.el.data('aspect-ratio');
        iframe.el.height(iframe.height);
    }
};