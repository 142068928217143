app.components.anchors = {
    onReady: function () {
        if ($('.c-anchors').length) {
            return true;
        }
    },
    addListener: function () {
        Promise.all([
            import(/* webpackChunkName: "sticky-sidebar" */ 'sticky-sidebar'),
            import(/* webpackChunkName: "util" */ 'bootstrap/js/dist/util'),
            import(/* webpackChunkName: "scrollspy" */ /* webpackExports: ["default", "named"] */ 'bootstrap/js/dist/scrollspy'),
        ]).then((modules) => {

            const stickySidebar = modules[0].default;

            new stickySidebar('.c-anchors', {
                topSpacing: 30,
                bottomSpacing: 0,
                containerSelector: '.section--right.section--background-image .container',
                innerWrapperSelector: '.c-anchors__inner',
                resizeSensor: true,
                stickyClass: 'is-affixed',
                minWidth: 330
            });

            $('body').scrollspy({
                target: '#c-anchors',
                offset: ($(window).height() / 3)
            });
        });
    }
};

