import $ from "expose-loader?exposes=$,jQuery!jquery";

/**
 * App object for managing components and initialization.
 */
window.app = {
    components: {}, // Object to store components.

    /**
     * Initializes the application.
     * Adds event listeners for resize, orientation change, and scroll.
     */
    init: function () {
        // Call the 'addListener' function on components.
        this.call('addListener');

        // Add event listeners for resize and orientation change.
        $(window).on('resize orientationchange', () => this.call('resize')).trigger('resize');

        // Add event listener for scroll.
        $(window).on('scroll', () => this.call('scroll')).trigger('scroll');
    },

    /**
     * Calls a specified event on all components.
     * @param {string|Object} event - The name of the event or an event object.
     */
    call: function (event) {
        var _return;
        if (typeof event === 'string') {
            event = {name: event, arg: this};
        }
        for (const element of Object.values(this.components)) {
            element.global = element.global || false;
            if (typeof element[event.name] === 'function' && (element.global || element.onReady())) {
                element[event.name](event.arg);
            }
        }
    },

};
