app.components.news_index = {
    onReady: function () {
        if ($('.c-news').length) {
            return true;
        }
    },
    addListener: function () {

        var that = this;

        var $container = $('.c-news'),
            $select = $('.c-news select#newsfilter__select');

        $select.change(function () {
            var button = $(this);
            var module = button.data('module');
            var template = button.data('template');
            var page = parseInt(button.data('page'));
            var category = button.val();
            $(button).addClass('loading');
            that.getNewsEntries(module, template, category, page);
        });

        $('button.load', $container).on('click', function () {
            var button = $(this);
            var module = button.data('module');
            var template = button.data('template');
            var page = parseInt(button.data('page'));
            var category = button.data('category');
            that.getNewsEntries(module, template, category, page);
            return false;
        });

    },
    getNewsEntries: function (module, template, category, page) {
        var $container =  $('.c-news');
        var button = $('button.load', $container);
        var endpoint = '?component=' + module + '&options=' + ['template:' + template, 'page:' + page, 'category:' + category].join(';');
        button.attr('disabled', 'disabled');
        button.fadeIn(250);
        $.get(endpoint, function (data) {
            if (data) {
                if (page > 1) {
                    $('.c-news__posts', $container).append(data);
                } else {
                    $('.c-news__posts', $container).html(data);
                }
                button.data('page', page + 1);
                button.data('category', category);
                button.prop("disabled", true).removeAttr("disabled");
            } else {
                button.fadeOut(250);
            }
            $('*', $container).removeClass('loading');
        });
    }
};
