app.components.back_to_top = {
    global: true,
    scroll: function () {

        if ($(window).scrollTop() > ($(document).height() - $('footer').height() - $(window).height())) {
            $('.c-back_to_top a').removeClass('hide');
        } else {
            $('.c-back_to_top a').addClass('hide');
        }

    }
};