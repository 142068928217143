app.components.gallery = {
    onReady: function () {
        if ($('.c-gallery').length) {
            return true;
        }
    },
    addListener: function () {

        Promise.all([
            import(/* webpackChunkName: "swiper.js" */ 'swiper/js/swiper.js'),
        ]).then(([Swiper]) => {

            const SwiperLib = Swiper.default;

            $('.c-gallery').each(function () {

                var slider = new SwiperLib($(".images", this), {
                    autoplay: false,
                    speed: 1200,
                    spaceBetween: 0,
                    loop: true,
                    loopedSlides: 8,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 2
                    },
                    wrapperClass: 'swiper-wrapper',
                    watchSlidesVisibility: true,
                    slidesPerView: 1,
                    watchOverflow: true,
                    mousewheel: {
                        forceToAxis: true
                    },
                    fadeEffect: {
                        crossFade: true
                    },
                    effect: 'fade',
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                });

                var thumbs = new SwiperLib($(".thumbnail", this), {
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    centeredSlides: true,
                    touchRatio: 0.2,
                    loop: true,
                    loopedSlides: 8
                });

                slider.controller.control = thumbs;
                thumbs.controller.control = slider;

            });

        });

    }
};
