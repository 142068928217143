app.components.offers = {
    global: false,
    onReady: function () {
        if ($('.c-offers--slider__wrapper').length) {
            return true;
        }
    },
    addListener: function () {

        Promise.all([
            import(/* webpackChunkName: "swiper.js" */ 'swiper/js/swiper.js'),
        ]).then(([Swiper]) => {

            const SwiperLib = Swiper.default;

            $('.c-offers--slider .content').matchHeight();

            $('.c-offers--slider').each(function () {
                var swiper = {
                    container: $(".swiper-container", this),
                    params: {
                        init: false,
                        speed: 3500,
                        spaceBetween: 0,
                        loop: true,
                        lazy: {
                            loadPrevNext: true,
                            loadPrevNextAmount: 2
                        },
                        slidesPerView: 'auto',
                        wrapperClass: 'swiper-wrapper',
                        watchSlidesVisibility: true,
                        watchOverflow: true,
                        mousewheel: {
                            forceToAxis: true
                        },
                        fadeEffect: {
                            crossFade: true
                        },
                        coverflowEffect: {
                            rotate: 30,
                            slideShadows: false
                        },
                        flipEffect: {
                            rotate: 30,
                            slideShadows: false
                        },
                        cubeEffect: {
                            slideShadows: false
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        },
                        autoplay: {
                            delay: 4000,
                            disableOnInteraction: true
                        },
                        watchSlidesProgress: true
                    }
                };

                swiper.params.watchSlidesProgress = true;
                swiper.instance = new SwiperLib(swiper.container, swiper.params);
                swiper.instance.init();

            });

        });

    }
};
