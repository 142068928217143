app.components.slider = {
    onReady: function () {
        if ($('.c-slider').length) {
            return true;
        }
    },
    addListener: function () {
        var that = this;

        Promise.all([
            import(/* webpackChunkName: "swiper.js" */ 'swiper/js/swiper.js'),
        ]).then(([Swiper]) => {

            const SwiperLib = Swiper.default;

            $('.c-slider').each(function () {

                $('.gallery', this).each(function() {
                    var gallery = this;
                    $("div.image:gt(0)", gallery).hide();
                    setInterval(function() {
                        $('div.image:first', gallery)
                            .fadeTo(1000, 0)
                            .next()
                            .fadeTo(1000, 1)
                            .end()
                            .appendTo(gallery);
                    }, 5000);
                });

                var params = JSON.parse( atob($(this).data('settings')) );

                var swiper = {
                    container: $(".swiper-container", this),
                    params: {
                        init: false,
                        speed: 1200,
                        spaceBetween: 0,
                        loop: false,
                        lazy: {
                            loadPrevNext: true,
                            loadPrevNextAmount: 2
                        },
                        wrapperClass: 'swiper-wrapper',
                        watchSlidesVisibility: true,
                        slidesPerView: 1,
                        watchOverflow: true,
                        mousewheel: {
                            forceToAxis: true
                        },
                        fadeEffect: {
                            crossFade: true
                        },
                        coverflowEffect: {
                            rotate: 30,
                            slideShadows: false
                        },
                        flipEffect: {
                            rotate: 30,
                            slideShadows: false
                        },
                        cubeEffect: {
                            slideShadows: false
                        }
                    }
                };

                if (params.slidesPerView > 1){
                    swiper.params.slidesPerView = 'auto';
                }

                if (params.loop){
                    swiper.params.loop = params.loop;
                    swiper.params.loopAdditionalSlides = 1;
                }

                if (params.effect){
                    swiper.params.effect = params.effect;
                }

                if (params.autoplay){
                    swiper.params.autoplay = {
                        delay: 6000,
                        disableOnInteraction: true
                    };
                    swiper.params.watchSlidesProgress = true;
                }

                if (params.navigation){
                    swiper.params.navigation = {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    };
                } else {
                    $('[class^="swiper-button-"]',  swiper.container).hide();
                }

                if (params.pagination) {
                    swiper.params.pagination = {
                        el: '.swiper-pagination',
                        type: 'fraction',
                        clickable: false
                    };
                }

                if ( $(".swiper-slide", swiper.container).length <= params.slidesPerView || (!params.navigation && !params.pagination)) {
                    swiper.params.allowSlidePrev = false;
                    swiper.params.allowSlideNext = false;
                    swiper.params.simulateTouch = false;
                    swiper.params.allowTouchMove = false;
                }

                swiper.instance = new SwiperLib(swiper.container, swiper.params);

                if (params.pagination) {
                    swiper.instance.on('paginationUpdate', function (swiper, paginationEl) {
                        var bulletWidth = $('.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)', paginationEl).eq(2).width();
                        var index = swiper.snapIndex;
                        var translateX = index ? swiper.snapIndex * bulletWidth : 0;
                        $('span', paginationEl).fadeTo(0, 1);
                        $(paginationEl).attr('style', 'transform: translateX(-' + translateX + 'px)');
                        var fade = .75;
                        while (index > 0) {
                            index--;
                            fade -= .25;
                            $('span', paginationEl).eq(index).fadeTo(.1, fade);
                        }
                    });
                }

                if (params.autoplay) {

                    swiper.instance.on('init', function () {
                        var swiper = this;
                        swiper.progessbar = {
                            el: $('.swiper-progessbar', swiper.container),
                            percent: 0,
                            frame: swiper.params.autoplay.delay / 1000,
                            active: true,
                            stop: function () {
                                this.reset();
                                this.el.remove();
                                this.active = false;
                            },
                            reset: function () {
                                this.percent = 0;
                                this.el.hide();
                                this.step();
                                this.el.removeClass('noTransition');
                            },
                            start: function () {
                                this.percent = 100;
                                this.el.show();
                                this.step();
                            },
                            step: function () {
                                this.el.css({"width": this.percent + "%"});
                            },
                            init: function () {
                                var that = this;
                                this.reset();
                                if (this.active) {
                                    setTimeout(function () {
                                        that.el.show();
                                        that.start();
                                    }, 100);
                                }
                            }
                        };
                    });

                    swiper.instance.on('init slideChange', function () {
                        var swiper = this;
                        if (swiper.autoplay.running == true) {
                            swiper.progessbar.init();
                        }
                        // pause/autoplay >>GLOBAL<< video on slidechange
                        $('.swiper-slide', swiper.container).each(function (i, e) {
                            $(this).find('video').each(function () {
                                if (i == swiper.activeIndex) {
                                    this.currentTime = 0;
                                    var video = this;
                                    var playPromise = video.play();
                                } else {
                                    if (playPromise !== undefined) {
                                        playPromise.then(_ => {
                                            video.pause();
                                        }).catch(error => { });
                                    }
                                }
                            });
                        });
                    });

                    swiper.instance.on('autoplayStop', function () {
                        var swiper = this;
                        swiper.progessbar.stop();
                    });

                }

                swiper.instance.init();

                setTimeout(function(){
                    swiper.instance.update();

                }, 1000);

            });

        });

    },
    progessbar: function () {
        var that = this;
    }
};
