app.components.newsletter = {
    onReady: function () {
        if ($('.display_archive').length) {
            return true;
        }
    },
    addListener: function () {

        $(".display_archive").children().each(function () {
            let $this = $(this);
            let datumString = $this.html().substr(0, 10);
            $this.html($this.html().replace(datumString, "").replace("- ", ""));
            const datumArray = datumString.split("/");
            $("a", $this).prepend(datumArray[1] + "." + datumArray[0] + "." + datumArray[2] + ": ");
        });
    }
};
