
$("html").removeClass("no-js");

// detect ie11
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if (isIE11) {
    $('html').addClass('ie11');
}

// add pdf class and open link in new window
$("a[href$='pdf']").attr("target", "_blank");

// Open External Links In New Window
$('a').each(function () {
    if ($(this).attr("href") && $(this).attr("href").indexOf("#") === 0) {
        return;
    }
    if ($(this).attr("href") && $(this).attr("href").indexOf("javascript") === 0) {
        return;
    }
    var a = new RegExp('/' + window.location.host + '/');
    var b = new RegExp(/(mailto:.*)/g);
    if (!a.test(this.href) && !b.test(this.href)) {
        //$(this).attr('rel', 'nofollow');
        $(this).attr("target", "_blank");
    } else {
        $(this).attr('rel', 'internal');
    }
});

// link has image
$('a:has(img)').addClass('hasImage');

if ($("body.scrollbehavior--default").length) {

    let scrollToElement = (hash) => {
        hash = hash.replace('#', '');
        let elementOffset = $('.section[data-anchor="' + hash + '"]').offset();
        if (!elementOffset) {
            elementOffset = $('#' + hash).offset();
        }
        if (elementOffset) {
            elementOffset = elementOffset.top;
            $('html, body').stop().animate({
                'scrollTop': elementOffset - 30
            }, 750, 'swing', function () {
                $(document).trigger("scroll");
            });
        }
    }

    // hash links
    $(window).on('hashchange', function () {
        var hash = window.location.hash;
        $('html.mobile-nav-is-visible').removeClass('mobile-nav-is-visible');
        if (hash) {
            setTimeout(() => {
                scrollToElement(hash);
            }, 100);
        }
    }).trigger('hashchange');

    $(document).on('click', 'a[href^="#"]', function (event) {
        var hash = $.attr(this, 'href');
        if (hash !== '#') {
            event.preventDefault();
            scrollToElement(hash);
            history.pushState(null, null, hash);
        }
    });
}
