app.components.loader = {
    global: true,
    addListener: function () {
        var that = this;

        // fallback: checks when content is loaded from cache
        that.checkStateInterval = setInterval(that.checkState, 100);

        // fallback: if something is wrong
        setTimeout(function () {
            $("html").addClass('complete');
            clearInterval(that.checkStateInterval);
        }, 1500);

        // checks when content is loaded
        $(window).on('load', function () {
            setTimeout(function () {
                $("html").addClass('complete');
            }, 100);
            clearInterval(that.checkStateInterval);
        });
    },
    checkState: function () {
        var that = this;
        if (document.readyState == "complete") {
            $("html").addClass('complete');
            clearInterval(that.checkStateInterval);
        }
    }
};
