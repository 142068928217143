app.components.fancybox = {
    onReady: function () {
        if ($('[data-fancybox]').length) {
            return true;
        }
        if ($('#fancybox-dialog').length) {
            return true;
        }
    },
    addListener: function () {

        Promise.all([
            import(/* webpackChunkName: "fancyapps" */ '@fancyapps/ui'),
            import(/* webpackChunkName: "fancyappscss" */ '@fancyapps/ui/dist/fancybox/fancybox.css')
        ]).then(([FancyboxModule, FancyboxCss]) => {

            const Fancybox = FancyboxModule.Fancybox;

            Fancybox.bind("[data-fancybox]", {
                Fullscreen: {
                    autoStart: false,
                },
                fitToView: true,
                Images: {
                    zoom: false,
                },
                closeButton: true,

                on: {
                    done: (fancybox, slide) => {
                        try {
                            wpforms.ready();
                        } catch (e) {
                        }
                    },
                },
            });

            Fancybox.show([{ src: "#fancybox-dialog", type: "inline" }]);

        }).catch(error => {
            console.error('Error loading modules:', error);
        });
    }
};
