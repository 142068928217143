app.components.navigation = {
    onReady: function () {
        if ($('.c-navigation__toggle').length) {
            return true;
        }
    },
    addListener: function () {

        $('.c-navigation__toggle').on("click", function () {
            $('html').toggleClass('mobile-nav-is-visible');
        });
        $(document).on('click', 'html.children-menu-is-visible', function (e) {
            if ($(e.target).is('body')) {
                $('.menu_item_open_children > a').trigger('click');
            }
        });

        $('header .menu li.menu_item_has_children > a').on('click', function (e) {
            let parent = $(this).parent('li');
            $('header .menu > li.menu_item_has_children').not(parent).removeClass('menu_item_open_children');
            if (!parent.hasClass('menu_item_open_children')) {
                parent.addClass('menu_item_open_children');
                $('html').addClass('children-menu-is-visible');
            } else {
                parent.removeClass('menu_item_open_children');
                $('html').removeClass('children-menu-is-visible');
            }
            $(this).parent('li').toggleClass('mouseenter');
            $(this).next('ul').addClass('menu_item_next');
            if ($('html').hasClass('mobile-nav-is-visible') || parent.parents('ul').length === 1) {
                return false;
            }
        });

        $('header .menu li.menu_item_has_children > a').each(function (i, e) {
            var that = this;
            var html = $('<div>').append($(that).clone()).html();
            $('<li class="menu_item menu_has_parents">' + html + '</li>').prependTo($(that).next('ul'));
        });

        $('header .menu > li.menu_item:eq(0) > a').each(function (i, e) {
            var that = this;
            $(".c-navigation__quicklinks li.quicklinks").appendTo($(that).next('ul'));
            $(".c-navigation__quicklinks").detach();
        });

        $('header .menu li.menu_has_parents').on('click', function () {
            var prev = $(this).parent('ul').first();
            prev.removeClass('menu_item_next');
            return false;
        });

    }
};

